import { Box } from '@mui/material'
import React from 'react'
import Page from '../components/page/page'
const SignUp = () => {
  return (
    <Page
    title="Sign Up"
    >
    <Box style={{marginTop:'5%'}}>
      Hello
    </Box>
    </Page>
  )
}

export default SignUp
